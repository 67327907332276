@import "variables.scss";


.loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #272727;
    .icon {
        width: 80px;

        path {
            fill: transparent;
            stroke: $color3;
            stroke-width: 10em;
            stroke-dasharray: 116000;
            stroke-dashoffset: 0;
            animation: animatesvg 1.8s linear forwards;
        }
    }
}

@keyframes animatesvg {
    0% {
        stroke-dashoffset: 115000;
    }
    40% {
        stroke-dashoffset: 174000;
    }
    80% {
        stroke-dashoffset: 232000;
        fill: transparent;
    }
    90% {
        fill: $color3;
    }
    100% {
        stroke-dashoffset: 232000;
        fill: $color3;
    }
}
