@import "variables.scss";

.btn {
    padding: 10px;
    background-color: $color3;
    border: white;
    border-radius: 5px;
    color: white;
    font-size: 0.9em;
    font-weight: bold;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
        background-color: $color2;
    }
}

main#about {
    .container {
        display: flex;
        align-items: center;
        img {
            width: 250px;
            margin-left: 200px;
        }
        .paragraph {
            max-width: 600px;
            p {
                font-size: 1.2em;
                margin-bottom: 20px;
                &:nth-child(2) {
                    margin-bottom: 40px;
                }
            }
            a {
                @extend .btn;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    main#about {
        .container {
            .paragraph {
                p {
                    font-size: 1em;
                }
            }
        }
    }
}
