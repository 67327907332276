@import "variables.scss";

.card {
    width: 380px;
    height: 240px;
    border-radius: 5px;
    .imgContainer {
        width: 100%;
        height: 75%;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            position: relative;
            z-index: -1;
        }
        .title {
            position: absolute;
            width: 100%;
            height: 100%;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.2s;
            h3 {
                display: none;
            }
            &:hover {
                background-color: $color3;
                h3 {
                    display: block;
                }
            }
        }
    }
    .iconContainer {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 25%;
        background-color: white;
        box-shadow: 0px -10px 25px -20px rgb(0 0 0 / 25%);
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        padding: 15px;
        .tech {
            display: flex;
            gap: 8px;
            *{
                margin: auto 0;
                transition: .1s
            }
            div{
                position: relative;
                span{
                    position: absolute;
                    top: -35px;
                    left: 50%;
                    transform: translateX(-50%);
                    background: $color3;
                    border-radius: 2px;
                    color: white;
                    padding: 5px 8px;
                    width: max-content;
                    font-size: .9em;
                    font-weight: 500;
                    opacity: 0;
                }
                svg {
                    font-size: 1.8em;
                    color: #d6d6dd;
                }
                &:hover{
                    span{
                        opacity: .9;
                    };
                    svg{
                        color: $color3;
                    }
                }
            }
        }
        i{
            font-size: 1.5em;
            color: rgb(45, 45, 45);
        }
    }
}

@media screen and (max-width:375px) {
    .card{
        height: 220px;
    }
}
