@import "About.scss";

form{
    width: 500px;
    input, textarea{
        display: block;
        margin-bottom: 20px;
        width: 100%;
        height: 40px;
        border-radius: 3px;
        border: 1px solid rgb(233, 233, 233);
        padding: 10px;
        &:focus{
            caret-color: $color3;
            outline: 1px solid $color3;
        }
    }
    textarea{
        height: 120px;
        resize: none;
        font-family: inherit;
    }
    button{
        @extend .btn;
        margin-bottom: 20px;
    }
}