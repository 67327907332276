@import "variables.scss";

%flex-center{
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu{
    display: none;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    position: sticky;
    top: 0;
    z-index: 100000;
    background-color: #f5f5f5;
    .stickers{
        height: 50px;
        img{
            height: 100%;
            cursor: pointer;
        }
    }
    
    i{
        font-size: 1.6em;
        background-color: $color3;
        color: white;
        padding: 5px 8px;
        border-radius: 2px;
        z-index: 1000;
        cursor: pointer;
    }
    i.fa-xmark{
        padding: 5px 11px;
    }
}

nav{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    min-width: 90px;
    height: 100vh;
    @extend %flex-center;
    background-color: $color2;
    border-right: 4px solid $color3;
        ul{
            width: 100%;
            li{
                @extend %flex-center;
                flex-direction: column;
                row-gap: 5px;
                font-size: .6em;
                padding: 12px;
                color: $color1;
                cursor: pointer;
                transition: .2s;
                border-bottom: 3px solid $color4;
            &:hover{
                background-color: $color1;
                color: $color3;
            }
                    i{
                        font-size: 2.7em;
                    }
        
                }
        }
        
}
a.active li{
    color: $color3;
}
a{
    &:last-child li{
        border: none;
    }
}

@media screen and (max-width:768px) {
    .menu{
        display: flex;
    }
    nav{
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        @extend %flex-center;
        background-color: $color2;
        border: none;
    }
}
@media screen and (min-width:768px) {
    nav{
        display: flex;
    }
}