// @tailwind base;
// @tailwind components;
@tailwind utilities;

@import "variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
    background-color: #fafafa;
    margin: 0;
    font-family: Poppins, Inter, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
#root {
    margin-left: 90px;
}
main {
    padding: 0 100px;
    h2 {
        font-size: 2em;
        color: $color3;
        padding: 20px 0;
    }
    .container {
        margin-bottom: 20px;
    }
}
footer {
    // position: absolute;
    // bottom: 0;
    // left: 50%;
    text-align: center;
    margin: 20px 0;
    a {
        color: $color3;
    }
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $color3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #0cc6ad;
}

@media screen and (max-width: 1108px) {
    footer {
        position: static;
        text-align: center;
    }
}

@media screen and (max-width: 768px) {
    #root {
        margin: 0;
    }
    main {
        padding: 0 10%;
    }
}

@media screen and (max-width: 320px) {
    footer {
        font-size: 0.8em;
    }
}
